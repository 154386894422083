import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import FMFLogo from '../assets/Findmyfacility_RGB_Portrait-Without_Text.png'
import {useNavigate} from "react-router-dom";

function Header() {

    const navigate = useNavigate();
    return (
        <>
            <Navbar className="bg-body-tertiary">
                <Container>
                    <div className="d-flex w-100 justify-content-between align-items-center">
                    <Navbar.Brand href="#home" className="ms-3" onClick={() => {
                                    window.location.href = 'https://www.findmyfacility.com';
                                }}>
                        <div style={{display:"flex",flexDirection:"row", alignItems:"center",justifyContent:"center",fontFamily:"Default"}}> {/* Wrap image in a div */}
                            <img
                                alt=""
                                src={FMFLogo}
                                width="40"
                                height="40"
                                style={{
                                    display: 'block',  // Ensure proper block-level rendering of the image
                                }}
                            />
                            <h3>findmyfacility</h3>
                        </div>
                    </Navbar.Brand>
                        <Nav className="ms-auto">
                            <Nav.Link href="https://www.findmyfacility.com/sports">Sports</Nav.Link>
                            <Nav.Link href="https://www.findmyfacility.com/facilities">Facilities</Nav.Link>
                            <Nav.Link href=" https://www.findmyfacility.com/blog">Blog</Nav.Link>
                            <Nav.Link href="https://www.findmyfacility.com/contact-us">Contact</Nav.Link>
                        </Nav>
                    </div>
                </Container>
            </Navbar>
        </>
    );
}

export default Header;

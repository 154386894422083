import React from 'react'
import SearchPage from './SearchPage'
import ToggleBar from './ToggleBar'
import Map from './Map123'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useState } from 'react';
import '../assets/styles/HomePage.css'
import SampleMap from "./SampleMap";
import Header from "./Header";


const HomePage = ({details,updateFilters}) => {

    console.log(details,'HOME')
    return (
        <>
            <SearchPage updateFilters={updateFilters}/>
            <Container fluid>
                <Row>

                    {/* Sidebar on the left */}
                    <Col xl={1}>
                        <div id="map-container">
                            <ToggleBar details={details} updateFilters={updateFilters}/>
                        </div>
                    </Col>

                    {/* Dashboard on the right */}

                    <Col xl={11} className='mt-4'>
                        <div id="map-container">
                            <SampleMap details={details}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default HomePage
import React, { useRef, useState,useCallback } from 'react';
import { APIProvider, Map, AdvancedMarker, InfoWindow, Marker,useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import '../assets/styles/SampleMap.css';
import fmf from '../assets/Findmyfacility_RGB_Portrait-Without_Text.png';

const Map123 = ({ details }) => {
  const Sampleposition = { lat: 51.510848, lng: -0.110344 };

  // State to control InfoWindow visibility
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [infoWindowVisible, setInfoWindowVisible] = useState(false); // New state to track visibility



  const handleClick =()=>{

    console.log("clicked")

  }

  const handleMouseover = () => {
    console.log('Marker clicked');
    setSelectedMarker(Sampleposition);
    setInfoWindowVisible(true); // Show InfoWindow on click
  };

  const handleMouseOut = () => {
    console.log('Mouse out');
    setSelectedMarker(null);
    setInfoWindowVisible(false); 
  };

  const mapStyles = [
    {
      featureType: 'road',
      stylers: [{ visibility: 'on' }],
    },
  ];

  const [markerRef, marker] = useAdvancedMarkerRef();

  const [infoWindowShown, setInfoWindowShown] = useState(false);

  // clicking the marker will toggle the infowindow
  const handleMarkerClick = useCallback(
    () => setInfoWindowShown(isShown => !isShown),
    []
  );

  // if the maps api closes the infowindow, we have to synchronize our state
  const handleClose = useCallback(() => setInfoWindowShown(false), []);

  return (
    <>
      <APIProvider apiKey='AIzaSyCjYemnQG_XW3JLmu3HifVheyWXFrFrjf4'>
        <Map
          style={{ width: '100vw', height: '100vh' }}
          defaultCenter={{ lat: 51.511848, lng: -0.110344 }}
          defaultZoom={15}
          mapId={'DEMO_MAP_ID'}
          options={{ styles: mapStyles }}
        >
          <AdvancedMarker
            ref={markerRef}
            position={Sampleposition}
            onClick={handleMarkerClick}
          >
            <img
              src={fmf}
              width={32}
              height={32}
              alt="Marker"
              onMouseOver={handleMarkerClick}
              onMouseOut={handleClose}
            />
          </AdvancedMarker>

          {infoWindowShown && (
            <InfoWindow anchor={marker} onClose={handleClose}>
            <h2>InfoWindow content!</h2>
            <p>Some arbitrary html to be rendered into the InfoWindow.</p>
            </InfoWindow>
            )}
        </Map>
      </APIProvider>
    </>
  );
};

export default Map123;


import { useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import '../Pagination/EventPagination.css'


const EventPagination = (props) =>{

    const paginationItems = Array.from({ length: 5 }, (_, index) => index + 1); 
    
    const handleClick = (event)=>{
        props.updateFilters({page:parseInt(event.target.innerText,10)})

    }

    const handleNext = () =>{
        const nextPage = props.page + 1;
        props.updateFilters({page : parseInt(nextPage,10)})
        
    }

    const handlePrevious = ()=>{
        const prevPage = props.page -1;
        props.updateFilters({page : parseInt(prevPage,10)})

}

    return (
        <Pagination>
            <Pagination.Prev onClick={handlePrevious} />
            {paginationItems.map((item) => (
                <Pagination.Item 
                    key={item} 
                    value={item} 
                    onClick={(e) => handleClick(e)}
                    active={props.page===item}
                    linkStyle={{
                        backgroundColor: props.page === item ? '#7800ff' : 'transparent',
                        color: props.page === item ? 'white' : 'black',
                        border: props.page === item ? '1px solid #7800ff' : '1px solid transparent',
                      }}
                    //linkStyle={{}}
                >
                    {item}
                </Pagination.Item>
            ))}
            <Pagination.Next onClick={handleNext} />
        </Pagination>
    );

}

export default EventPagination;